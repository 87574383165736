<template>
  <b-card no-body>
    <div class="m-2">
      <b-row>
        <b-col
          cols="12"
          md="9"
          class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
        >
          <div class="d-flex align-items-center justify-content-end">
            <b-form-input
              v-model="searchQuery"
              @keyup="GetTotal"
              class="d-inline-block mr-1"
              :placeholder="$t('globalActions.find')"
            />
          </div>
        </b-col>
        <b-col
          cols="12"
          md="3"
          class="d-flex align-items-center justify-content-end mb-1 mb-md-0"
        >
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            pill
          >
            Total : {{formatCurrency(totals, false)}}
          </b-button>
        </b-col>
      </b-row>
      <!-- <div class="demo-inline-spacing">
        <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="outline-success"
            pill
          >
            Total : {{formatCurrency(totals, false)}}
          </b-button>
      </div> -->
    </div>
        <b-table
          id="refListTable"
          ref="refListTable"
          no-provider-paging
          no-provider-filtering
          :filter-included-fields="columnToBeFilter"
          :fields="tableColumns"
          :items="itemLists"
          :per-page="perPage"
          :current-page="currentPage"
          :filter="searchQuery"
          responsive
          primary-key="id"
          show-empty
          :busy="isBusy"
          :empty-text="$t('globalSingular.noMatching')"
          :sort-desc.sync="isSortDirDesc"
          class="position-relative"
        >
        <template #table-busy>
            <div class="text-center text-primary my-2 d-flex flex-column justify-content-center">
                <div class="text mb-1">
                    <b-spinner class="align-middle" />
                </div>
                <strong>{{ $t('globalActions.fetchingDataAPI') }}</strong>
            </div>
        </template>    
        <!-- Column: form number -->
        <template #cell(no_inv)="{item}">
          <b-link
            :to="{ name: 'apps-transactions-purchase-payment-per-voucher-add-outs', params: { id: item.id_invoice, vendor: item.contact_id }}"
            class="font-weight-bolder"
          >
            <span class="font-weight-bolder d-block text-nowrap">
              {{ item.no_inv }}
            </span>
          </b-link>
        </template>

      <template #cell(invoice_amount)="{ item }">
        <span :class="['font-weight-bolder', 'd-block', 'text-nowrap', (item.invoice_amount > 0) ? 'text-success' : 'text-danger' ]">
          <div v-if="item.invoice_amount > 0">
              {{ formatCurrency(item.invoice_amount, false) }}
          </div>
        </span>
      </template>

    </b-table>
  </b-card>
</template>
<script>
import {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BSpinner,
    BLink
} from 'bootstrap-vue'
import useListTable from '@/comp-functions/useListTable'
import { formatCurrency } from '@/utils/formatter'
import useLocalization from '@/comp-functions/utils/useLocalization'
import useHttp from '@/comp-functions/useHttp'
import { ref, onMounted } from '@vue/composition-api'
// import { BBadge } from 'bootstrap-vue'
import { BButton } from 'bootstrap-vue'
import Ripple from 'vue-ripple-directive'


export default {
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BTable,
    BSpinner,
    BLink,
    // BBadge,
    BButton
  },
  directives: {
    Ripple,
  },
  setup () {
    const { $t }   = useLocalization()
    const { $get } = useHttp()

    const tableColumns = [
      { key: 'no_inv', label: 'No Invoice' },
      { key: 'invoice_amount', label: 'Amount'},
      { key: 'office_name', label: $t('globalSingular.branch') },
      { key: 'code', label: 'No Form Payment' },
      { key: 'vendor', label: 'vendor' }
    ]
    const {
        searchQuery,
        itemLists,
        perPage,
        currentPage,
        isBusy,
        isSortDirDesc
    } = useListTable({ url: 'transaction/purchase/posting-ap-payment-outstanding' })

    
// Table Handlers
    const columnToBeFilter = [
      'code',
      'payment_type_code',
      'no_inv',
      'vendor'
    ]
    let totals = ref(0)

    const GetTotal = async () => {
          const dataParams = [
              `search=${searchQuery.value}`,
          ]
          const data = await  $get({ url: `value/GetTotal?${ dataParams.join('&') }`})
          totals.value = data.data
    }
    onMounted(async () => {
      GetTotal()
    })
    
    return {
        searchQuery,
        columnToBeFilter,
        tableColumns,
        itemLists,
        perPage,
        currentPage,
        isBusy,
        isSortDirDesc,
        formatCurrency,
        GetTotal,
        totals
    }
  },
  computed: {
    companyActive () {
      return this.$store.state.user.currentCompany
    }
  }
}
</script>

